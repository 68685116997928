<template>
	<div 
		id="contentClick" 
		:class="cls" 
		@click.prevent.stop="closeMenu" 
	/>
	<aside :class="cls">
		<img src="../assets/e732bd_368bd863da9d41fa93e195d804e0c220~mv2.png" alt="Cartório de Sobradinho - 2º Ofício" />
		<user-vue />
		<SideMenuVue />
		<ContentAreaVue>
			<div class="htmlContent"
				 v-html="localAppSettings.warningText || ''"
			>
			</div>
		</ContentAreaVue>			
	</aside>
</template>

<script>
import { computed, onMounted, reactive } from '@vue/runtime-core'
import { useStore } from 'vuex'
import SideMenuVue from './SideMenu.vue'
import UserVue from "./User.vue"
import { GlobalSettingsConstants } from '../plugins/constants'
import ContentAreaVue from '../components/shared/ContentArea.vue';
import { isEmpty, getLastSettingsVersion } from '../plugins/props';

export default {
	components: {
		UserVue,
		SideMenuVue,
		ContentAreaVue,
	},
	setup() {
		const store = useStore();

		const localAppSettings = reactive({
			warningText: '<p></p>',
		});

		const ObterUltimaVersaoGlobalAppSettings = computed(() => store.state.swagger.ObterUltimaVersaoGlobalAppSettings);
		const onHandleDispatchOperation = (operationId, payload) => store.dispatch(operationId, payload);

		const loadAppSettings = async () => {
			ObterUltimaVersaoGlobalAppSettings.value.parameters.nome = GlobalSettingsConstants.ApplicationDefaultSettings.MENU_WARNING_TEXT_KEY;
			ObterUltimaVersaoGlobalAppSettings.value.parameters.usuarioUid = GlobalSettingsConstants.ApplicationDefaultSettings.Common.GUID_EMPTY;

			await onHandleDispatchOperation('ObterUltimaVersaoGlobalAppSettings');

			const menuWarningTextSettings = ObterUltimaVersaoGlobalAppSettings.value?.result;

			console.log('ListaGlobalAppSettings::settings', menuWarningTextSettings)

			if (!isEmpty(menuWarningTextSettings?.valor)) {
				localAppSettings.warningText = menuWarningTextSettings?.valor;
			}
		};
		onMounted(() => loadAppSettings());

		/*
			Calculando a classe de acordo com 
			a info de state que precisamos
		*/
		const cls = computed(() => store.state.aside.isOpen ? "open" : "")
		const closeMenu = () => store.commit("closeMenu")
		return {
			cls,
			closeMenu,
			localAppSettings
		}
	}
}

</script>

<style scoped>
aside {
	background: var(--el-color-primary);
	color: var(--el-color-primary-light-9);
	z-index: 2001;
	padding-top: var(--headerHeight);
	position: fixed;
	width: 280px;
	overflow-y: auto;
	height: calc(100vh - var(--headerHeight));
	transform: translate(-100%);
	transition: transform 450ms ease-in;

}

/*
		Invertendo as scores da barra de rolagem no aside
	*/
aside::-webkit-scrollbar {
	background-color: var(--el-color-primary);
}

aside::-webkit-scrollbar-thumb {
	background: var(--el-color-primary-light-5);
}

/*
		Layout com aside a partir do tamanho médio
	*/
@media (--medium-viewport) {
	aside {
		grid-area: aside;
		display: block;
		transform: translate(0%);
		padding: var(--spacing-large) 0;
		height: calc(100vh - calc(var(--spacing-large) * 2));
	}
}

.open {
	transform: translate(0%);
	transition: transform 150ms ease-in;
}

#contentClick {
	z-index: 2000;
	pointer-events: none;
	width: 100vw;
	height: 100vh;
	position: fixed;
	grid-area: 1/-1;
}

#contentClick.open {
	pointer-events: all;
}

img {
	display: none;
}

@media (--medium-viewport) {
	img {
		display: block;
		width: calc(100% - calc(var(--spacing-large) * 2));
		border-radius: calc(var(--spacing));
		margin: var(--spacing-large);
	}
}
.htmlContent {
	padding: 5px 15px; 
	margin: 10px 1px 0px 1px; 
	border-style: solid;
	font-size: smaller;
	border-width: 1px;
}
</style>
